import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    templateUrl: './business-glossary.component.html',
    styleUrls: ['./business-glossary.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive],
})
export class BusinessGlossaryComponent {
  scrollToElement($element: HTMLElement): boolean {
    console.log($element);
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
    return false;
  }
}
