import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    templateUrl: './data-governance.component.html',
    styleUrls: ['./data-governance.component.scss'],
    standalone: true,
    imports: [RouterLink],
})
export class DataGovernanceComponent {}
