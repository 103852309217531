import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Observable } from 'rxjs';

import { ClientSearch, ClaimsService } from '@dbh/claims/data-access';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    templateUrl: './client-search.component.html',
    styleUrls: ['./client-search.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive,
        NgIf,
        NgFor,
        AsyncPipe,
    ],
})
export class ClientSearchComponent {
  searchTerm = '';
  searchResults$: Observable<ClientSearch> | undefined = undefined;

  parameters$ = this.route.queryParamMap.subscribe((parameters) => {
    this.searchTerm = parameters.get('search') || '';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.searchResults$ =
      this.searchTerm === ''
        ? undefined
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          this.claimsService.clientSearch(this.searchTerm);
  });

  constructor(
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
    private router: Router
  ) {}

  setSearchTerm(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchTerm = target.value;
  }

  submitSearch() {
    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: this.searchTerm },
    });
  }
}
