import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    templateUrl: './ite-database-naming-conventions.component.html',
    styleUrls: ['./ite-database-naming-conventions.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive],
})
export class IteDatabaseNamingConventionsComponent {}
