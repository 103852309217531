// Raw API response types
type MetricData = {
  key_type: 'R' | 'O' | 'RC';
  count96: number;
  count97: number;
  count98: number;
  no_count: number;
};

export type RawKeyMetricsResponse = {
  episode: {
    referral_source: MetricData;
    num_of_prior_su_episodes: MetricData;
    discharge_date: MetricData;
    discharge_reason: MetricData;
    arrests_past_30days_admission: MetricData;
    [key: string]: MetricData;
  };
  client_profile: {
    education: MetricData;
    employment: MetricData;
    ethnicity: MetricData;
    gender: MetricData;
    [key: string]: MetricData;
  };
  [key: string]: { [key: string]: MetricData };
};

// Transformed types that we use in the UI
export type ExtractKeyMetric = {
  measure: string;
  key_type: 'R' | 'O' | 'RC';
  count96: number;
  count97: number;
  count98: number;
  no_count: number;
};

export type MetricsSection = {
  name: string;
  metrics: ExtractKeyMetric[];
};

export type ExtractKeyMetricsResponse = MetricsSection[];
